.game {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color);
    color: var(--text-color);
  }  
  .game h1 {
    text-align: center;
    color: var(--text-color);
    margin-bottom: 30px;
    margin-left: -24px; /* Added margin-left to move title slightly left */
  }
  
  .categories {
    display: grid;
    gap: 20px;
  }
  
  .category {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .category.completed {
    order: -1;
  }
  
  .category h2 {
    margin: 0;
    padding: 10px;
    border-radius: 6px;
    color: #fff;
    text-align: center;
  }
  
  .word-box {
    aspect-ratio: 2;
    background-color: var(--word-box-background);
    border: 2px solid var(--word-box-border);
    border-radius: 16px;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    transition: all var(--transition-speed) ease;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: span 1;
    height: 60px;
    color: var(--text-color);
  }
  
  .word-box:hover {
    background-color: var(--word-box-hover);
  }
  
  .word-box.selected {
    background-color: var(--word-box-selected);
    border-color: var(--word-box-selected-border);
    color: var(--text-color);
  }
  
  .completed .word-box {
    cursor: default;
    color: white;
    border: none;
  }
  
  .shuffle-button {
    margin: 10px auto;
    padding: 8px 24px;
    background-color: transparent;
    color: #4a4a4a;
    border: 1px solid #4a4a4a;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }
  
  
  .away-button {
    display: block;
    margin: 10px auto;
    padding: 8px 16px;
    background-color: #666;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .away-button:hover {
    background-color: #555;
  }
  
  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    75% { transform: translateX(10px); }
  }
  
  .shake {
    animation: shake 0.5s ease-in-out;
  }
  
  .word-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(60px, auto);
    gap: 10px;
    position: relative;
    margin: 20px auto;
    width: 100%;
    max-width: 800px;
  }
  
  .submit-button {
    padding: 8px 24px;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: transform 0.2s ease;
  }
  
  .submit-button:hover:not(:disabled), .shuffle-button:hover:not(:disabled) {
    transform: scale(1.05);
  }
  
  .submit-button:active:not(:disabled), .shuffle-button:active:not(:disabled) {
    transform: scale(0.95);
  }
  
  .submit-button:disabled, .shuffle-button:disabled {
    background-color: var(--button-disabled-background);
    color: var(--button-disabled-text);
    cursor: not-allowed;
    transform: none;
  }
  
  @keyframes fadeInOut {
    0% { opacity: 0; }
    15% { opacity: 1; }
    85% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .game-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 20px;
    width: 100%;
    margin-left: -15px; /* Added margin-left to move title slightly left */
    margin-bottom: 50px;
  }
  
  .game-header h1 {
    margin: 0;
    text-align: center;
  }
  
  .completed-categories {
    padding: 16px 30px;
    margin-bottom: 15px;
    border-radius: 20px;
  }
  
  
  .category-name {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 8px;
    color: black;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  .category-words {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
    color: black;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .game-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 20px auto;
    width: 100%;
  }
  
  .submit-button {
    margin: 0;
  }
  
  .shuffle-button {
    margin: 0;
  }
  
  .playing-area {
    position: relative;
    margin-top: 40px;
    min-height: 300px;
    width: 100%;
    overflow: visible;
  }
  
  @keyframes gatherAndFloat {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(-40px);
      opacity: 0;
    }
  }
  
  .word-box.selected.correct {
    animation: gatherAndFloat 0.5s ease-out forwards;
  }
  
  @keyframes gatherAndCelebrate {
    0% {
      transform: scale(1) translate(0, 0);
      opacity: 1;
    }
    40% {
      transform: scale(0.8) translate(var(--gather-x), var(--gather-y));
      background-color: var(--category-color);
      color: white;
      opacity: 1;
    }
    60% {
      transform: scale(1.1) translate(var(--gather-x), var(--gather-y));
      background-color: var(--category-color);
      color: white;
      opacity: 1;
    }
    100% {
      transform: scale(0) translate(var(--gather-x), calc(var(--gather-y) - 100px));
      background-color: var(--category-color);
      color: white;
      opacity: 0;
    }
  }
  
  .word-box.selected.correct {
    animation: gatherAndCelebrate 1s ease-out forwards;
    animation-delay: calc(var(--sequence) * 0.1s);
  }
  
  .attempts-counter {
    position: absolute;
    right: 20px;
    top: 90%;
    transform: translateY(-50%);
    font-size: 1.1rem;
    color: var(--text-color);
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  
  .one-away-message {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    z-index: 1000;
    animation: fadeInOut 2s ease-in-out;
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }
  
  @keyframes fadeInOut {
    0% { opacity: 0; }
    15% { opacity: 1; }
    85% { opacity: 1; }
    100% { opacity: 0; }
  }

  .game {
    display: grid;
    grid-template-columns: 250px 1fr 250px;
    gap: 20px;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
  }
  
  .stats-panel {
    position: sticky;
    top: 20px;
    height: fit-content;
    background: var(--panel-background);
    border-radius: 12px;
    padding: 20px;
    box-shadow: var(--card-shadow);
    margin: 0;
    width: 100%;
    color: var(--text-color);
    margin-left: -50px;
  }

  .opponent-panel {
    position: sticky;
    top: 10px;
    height: fit-content;
    background: var(--panel-background);
    border-radius: 12px;
    padding: 20px;
    box-shadow: var(--card-shadow);
    margin: 0;
    width: 100%;
    color: var(--text-color);
  }
  
  .timer {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .time-display {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
  }
  
  .stat-item {
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
    color: var(--text-color);
  }
  
  .opponent-progress {
    margin: 12px 0;
  }
  
  .opponent-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
    font-weight: 500;
    position: relative;
  }
  
  .completion-time {
    font-size: 14px;
    opacity: 0.8;
    position: absolute;
    right: -100px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .category-indicators {
    display: flex;
    gap: 4px;
  }
  
  .category-indicator {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .submit-button, .shuffle-button, .new-game-button {
    padding: 8px 24px;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: transform 0.2s ease;
  }
  
  .submit-button:hover, .shuffle-button:hover, .new-game-button:hover {
    background-color: #808080;
    transform: scale(1.05);
  }
  
  .submit-button:active, .shuffle-button:active, .new-game-button:active {
    transform: scale(0.95);
  }
  
  .submit-button:disabled, .shuffle-button:disabled {
    background-color: var(--word-box-border);
    cursor: not-allowed;
    transform: none;
  }
  
  .game-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 20px auto;
    width: 100%;
  }

  .home-link {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 100;
    color: var(--text-color);
    text-decoration: none;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 20px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .home-link:hover {
    background-color: var(--word-box-hover);
  }

  .hearts-container {
    display: flex;
    gap: 8px;
    margin-top: 2px;
    justify-content: center;
  }

  .heart {
    color: #ff4444;
    font-size: 24px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: relative;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes heartBreak {
    0% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      transform: translateX(0);
      opacity: 1;
    }
    20% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      transform: translateX(0) scale(1.2);
      opacity: 1;
    }
    40% {
      clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
      transform: translateX(-10px) rotate(-10deg);
      opacity: 0.5;
    }
    41% {
      clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
      transform: translateX(10px) rotate(10deg);
      opacity: 0.5;
    }
    100% {
      clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
      transform: translateX(20px) rotate(20deg);
      opacity: 0;
    }
  }

  .heart.broken {
    animation: heartBreak 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }

  .completed-category-banner {
    width: 100%;
    max-width: 605px;
    min-width: 605px;
    padding: 20px;
    border-radius: 16px;
    text-align: center;
    margin: 0 auto 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .game-header {
      padding: 0 10px;
      margin-left: 0;
    }

    .attempts-counter {
      position: static;
      transform: none;
      margin: 10px auto;
    }

    .home-link {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .category-words {
      font-size: 14px;
    }

    .game-buttons {
      flex-wrap: wrap;
    }

    .submit-button, .shuffle-button, .new-game-button {
      width: 100%;
      margin: 5px 0;
    }

    .completed-category-banner {
      max-width: 100%;
      min-width: 280px;
    }
  }

  @media (max-width: 1200px) {
    .game {
        grid-template-columns: 1fr;
    }

    .stats-panel,
    .opponent-panel {
        position: static;
        max-width: 600px;
        margin: 0 auto;
    }
  }

  @media (max-width: 768px) {
    .game {
        grid-template-columns: 1fr;
        min-width: 320px;
        overflow-x: auto;
    }

    .stats-panel,
    .opponent-panel {
        position: static;
        max-width: 600px;
        margin: 10px auto;
        min-width: 280px;
    }

    .home-link {
        position: static;
        display: inline-flex;
        margin: 10px auto;
        justify-content: center;
    }

    .game-header {
        flex-direction: column;
        align-items: center;
        margin: 10px 0;
        padding-top: 40px;
    }

    .word-grid {
        min-width: 280px;
        margin: 10px auto;
    }

    .completed-category-banner {
        min-width: 280px;
        width: 100%;
    }

    .word-box {
        height: 50px;
        font-size: 16px;
    }
  }

  /* Additional breakpoint for very small screens */
  @media (max-width: 320px) {
    .game {
        padding: 10px;
    }

    .word-box {
        font-size: 14px; /* Slightly smaller font for very small screens */
        height: 45px;
    }
  }

  @media (orientation: landscape) and (max-width: 768px) {
    .game-header h1 {
        width: 100%;
        text-align: left;
        margin-left: 40px;
    }

    .attempts-counter {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }

    .home-link {
        position: absolute;
        left: 20px;
        top: 0%;
        transform: translateY(-50%);
    }
  }

  .opponent-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .opponent-lives {
    display: flex;
    gap: 2px;
    margin-bottom: -47px;
  }

  .opponent-lives .heart {
    font-size: 20px;
  }
  
  