:root {
  --background-color: #ffffff;
  --text-color: #333333;
  --card-background: #ffffff;
  --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --button-background: #4a4a4a;
  --button-text: #ffffff;
  --word-box-background: #e6e6e6;
  --word-box-border: #d4d4d4;
  --word-box-hover: #d4d4d4;
  --word-box-selected: #a5d8ff;
  --word-box-selected-border: #74c0fc;
  --stats-text: #4a4a4a;
  --button-disabled-background: #4d4d4d;
  --button-disabled-text: #8a8a8a;
  --panel-background: #f5f5f5;
}

[data-theme='dark'] {
  --background-color: #1a1a1a;
  --text-color: #ffffff;
  --card-background: #2d2d2d;
  --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  --button-background: #666666;
  --button-text: #ffffff;
  --word-box-background: #3d3d3d;
  --word-box-border: #4d4d4d;
  --word-box-hover: #4d4d4d;
  --word-box-selected: #4c6ef5;
  --word-box-selected-border: #5c7cfa;
  --stats-text: #ffffff;
  --button-disabled-background: #2d2d2d;
  --button-disabled-text: #666666;
  --panel-background: #2a2a2a;
}
