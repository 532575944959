.theme-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--text-color);
  background: var(--card-background);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 1000;
  box-shadow: var(--card-shadow);
}

.theme-toggle:hover {
  transform: scale(1.1);
}

.theme-toggle:active {
  transform: scale(0.95);
}

.theme-toggle .icon {
  font-size: 20px;
  position: absolute;
}

.theme-toggle.light .moon {
  opacity: 0;
}

.theme-toggle.dark .sun {
  opacity: 0;
} 