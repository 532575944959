.rooms-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
  }
  
  .rooms-header {
    text-align: center;
    margin: 40px 0;
  }
  
  .rooms-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .room-card {
    background-color: var(--panel-background);
    border-radius: 12px;
    padding: 20px;
    box-shadow: var(--card-shadow);
    width: 300px;
    flex-shrink: 0;
  }
  
  .room-info h3 {
    margin: 0 0 10px 0;
    color: var(--text-color);
  }
  
  .player-count, .game-status {
    color: var(--text-color);
    opacity: 0.8;
    margin: 5px 0;
  }
  
  .join-button {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .join-button:hover:not(:disabled) {
    transform: scale(1.02);
  }
  
  .join-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .no-rooms {
    text-align: center;
    color: var(--text-color);
    opacity: 0.8;
    margin-top: 40px;
  }
  
  .rooms-loading {
    text-align: center;
    color: var(--text-color);
    margin-top: 40px;
  }