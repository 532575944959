.landing-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  overflow-x: hidden;
}

.landing-content {
  width: 90%;
  max-width: 1200px;
  text-align: center;
  padding: clamp(16px, 3vw, 32px);
  border-radius: 16px;
  background-color: var(--panel-background);
  box-shadow: var(--card-shadow);
}

.landing-title {
  font-size: clamp(1.8rem, 4vw, 3rem);
  margin-bottom: 8px;
  color: var(--text-color);
}

.landing-subtitle {
  font-size: clamp(0.9rem, 2vw, 1.2rem);
  color: var(--text-color);
  opacity: 0.8;
  margin-bottom: 16px;
}

.game-preview {
  margin: 30px 0;
}

.preview-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(4px, 0.8vw, 8px);
  max-width: min(350px, 90%);
  margin: 16px auto;
}

.preview-box {
  background-color: var(--word-box-background);
  border: 2px solid var(--word-box-border);
  border-radius: 10px;
  padding: clamp(8px, 1.5vw, 12px);
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  font-weight: 500;
  color: var(--text-color);
}

.landing-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 30px auto;
  max-width: 900px;
  padding: 0 10px;
}

.feature {
  padding: 20px;
  border-radius: 12px;
  background-color: var(--background-color);
  transition: transform 0.2s ease;
}

.feature-emoji {
  font-size: 2rem;
  display: block;
  margin-bottom: 10px;
}

.how-to-play {
  margin-top: 40px;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 12px;
}

.how-to-play ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.how-to-play li {
  margin: 10px 0;
  color: var(--text-color);
  opacity: 0.9;
}

.play-button {
  padding: 15px 40px;
  font-size: 1.2rem;
  background-color: var(--button-background);
  color: var(--button-text);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin: 30px 0;
}

.play-button:hover {
  transform: scale(1.05);
  background-color: #808080;
}

@media (max-width: 768px) {
  .landing-features {
    grid-template-columns: 1fr;
  }
  
  .preview-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.competitive-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 32px;
  padding: 16px;
  background-color: var(--background-color);
  border-radius: 10px;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-number {
  font-size: clamp(1.5rem, 3.5vw, 2.2rem);
  font-weight: bold;
  color: var(--text-color);
}

.stat-label {
  font-size: 0.8rem;
  color: var(--text-color);
  opacity: 0.8;
  text-align: center;
  margin-top: 4px;
}

.play-button {
  padding: 15px 40px;
  font-size: 1.2rem;
  background-color: var(--button-background);
  color: var(--button-text);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin: 30px 0;
}

.play-button:hover {
  transform: scale(1.05);
  background-color: #808080;
}

/* Hero Section */
.hero-section {
  padding: 40px 0;
  margin-bottom: 40px;
}

.hero-description {
  max-width: 600px;
  margin: 20px auto;
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--text-color);
  opacity: 0.9;
}

/* How to Play Section */
.how-to-play-section {
  padding: 40px 0;
  margin: 40px 0;
  background-color: var(--background-color);
  border-radius: 20px;
}

.how-to-play-section h2 {
  font-size: 2rem;
  margin-bottom: 30px;
}

.steps-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 0 20px;
}

.step {
  text-align: center;
  padding: 20px;
  background-color: var(--panel-background);
  border-radius: 15px;
  transition: transform 0.2s ease;
}

.step:hover {
  transform: translateY(-5px);
}

.step-number {
  width: 40px;
  height: 40px;
  background-color: var(--button-background);
  color: var(--button-text);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
  font-weight: bold;
  font-size: 1.2rem;
}

/* Footer */
.landing-footer {
  margin-top: 60px;
  padding: 20px 0;
  border-top: 1px solid var(--border-color);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-links {
  display: flex;
  gap: 20px;
}

.footer-links a {
  color: var(--text-color);
  text-decoration: none;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.footer-links a:hover {
  opacity: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .steps-grid {
    grid-template-columns: 1fr;
  }

  .footer-content {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .landing-content {
    width: 95%;
    padding: clamp(12px, 2.5vw, 24px);
  }

  .landing-features {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  
  .preview-grid {
    grid-template-columns: repeat(2, 1fr);
    max-width: min(280px, 95%);
  }

  .competitive-stats {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .stat {
    padding: 8px 0;
  }
}

@media (max-width: 480px) {
  .landing-title {
    font-size: clamp(1.5rem, 3.5vw, 2.5rem);
  }

  .landing-subtitle {
    font-size: clamp(0.8rem, 1.8vw, 1rem);
  }

  .play-button {
    padding: 10px 28px;
    font-size: 1rem;
  }
}

.username-input-container {
  margin: 20px 0;
  width: 100%;
  max-width: 300px;
}

.username-input {
  width: 100%;
  padding: 12px;
  border: 2px solid var(--panel-background);
  border-radius: 8px;
  background: var(--word-box-background);
  color: var(--text-color);
  font-size: 16px;
  transition: border-color 0.2s;
}

.username-input:focus {
  outline: none;
  border-color: var(--button-background);
}

.login-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 32px 0;
}

.username-input {
  width: 100%;
  max-width: 300px;
  padding: 12px 20px;
  font-size: 1rem;
  border: 2px solid var(--word-box-border);
  border-radius: 10px;
  background: var(--word-box-background);
  color: var(--text-color);
  text-align: center;
  transition: border-color 0.2s;
}

.username-input:focus {
  outline: none;
  border-color: var(--button-background);
}

.username-input::placeholder {
  color: var(--text-color);
  opacity: 0.6;
} 