.game-room {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.room-info {
  background: var(--panel-background);
  padding: 20px;
  border-radius: 12px;
  box-shadow: var(--card-shadow);
}

.players-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.player {
  background: var(--word-box-background);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
} 