.create-room-modal {
  max-width: 500px;
  margin: 40px auto;
  padding: 30px;
  background: var(--panel-background);
  border-radius: 12px;
  box-shadow: var(--card-shadow);
}

.create-room-modal h2 {
  margin-top: 0;
  color: var(--text-color);
}

.create-room-modal input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--word-box-border);
  border-radius: 6px;
  background: var(--word-box-background);
  color: var(--text-color);
}

.room-privacy {
  margin: 15px 0;
  color: var(--text-color);
}

.modal-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  background: var(--button-background);
  color: var(--button-text);
}

.copy-link-button {
  background: var(--button-background);
  color: var(--button-text);
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}

.room-name-input {
  width: 100%;
  padding: 12px;
  margin: 20px 0;
  border: 2px solid var(--word-box-border);
  border-radius: 8px;
  background: var(--word-box-background);
  color: var(--text-color);
}

.room-type-selection {
  margin: 25px 0;
}

.room-type-option {
  display: block;
  padding: 15px;
  margin: 10px 0;
  border: 2px solid var(--word-box-border);
  border-radius: 8px;
  cursor: pointer;
}

.room-type-option:hover {
  border-color: var(--button-background);
}

.room-type-label {
  font-weight: bold;
  margin-left: 10px;
}

.room-type-description {
  margin: 5px 0 0 25px;
  font-size: 0.9em;
  opacity: 0.8;
}

.room-link-container {
  margin-top: 20px;
  text-align: center;
}

.room-link {
  display: flex;
  gap: 10px;
  margin: 15px 0;
}

.room-link-input {
  flex: 1;
  padding: 10px;
  border: 2px solid var(--word-box-border);
  border-radius: 8px;
  background: var(--word-box-background);
  color: var(--text-color);
}

.copy-link-button,
.join-room-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background: var(--button-background);
  color: var(--button-text);
  cursor: pointer;
}

.modal-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 30px;
}

.cancel-button,
.create-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.cancel-button {
  background: var(--word-box-background);
  color: var(--text-color);
}

.create-button {
  background: var(--button-background);
  color: var(--button-text);
} 